import './Resume.css';
import TextLoop from "react-text-loop";


function Resume() {
    return(
        <div className="resume-container">
            <br/>
            <div className="resume">
                <h2 className="resume-label">Experience</h2>
                <div className="experience-container">
                <div className="experience">
                        <h3 className="experience-title">IP-SM-GW server supervisor</h3>
                        <p className="experience-date">01/2021</p>
                        <h3 className="experience-company">TXM</h3>
                        <p className="experience-description">
                        • Supervise the Ericsson IPSMGW server/node<br/>
                        • Handle alarms, file systems and metrics<br/>
                        • Use Linux command line to supervise the node
                        </p>
                    </div>
                    <div className="experience">
                        <h3 className="experience-title">Web App Developer Intern</h3>
                        <p className="experience-date">05/2019 - 08/2019</p>
                        <h3 className="experience-company">Omnifon</h3>
                        <p className="experience-description">
                        • Designed a secure information sharing web application<br/>
                        • Html  JavaScript  PHP  MySQL
                        </p>
                    </div>
                    <div className="experience">
                        <h3 className="experience-title">Web App Developer freelance</h3>
                        <p className="experience-date">05/2017 - 08/2017</p>
                        <h3 className="experience-company">TXM</h3>
                        <p className="experience-description">
                        • Developed an employee's travel expenses manager web app<br/>
                        • Html  JavaScript  PHP  MySQL
                        </p>
                    </div>
                </div>
                <h2 className="resume-label">Education</h2>
                <div className="education-container">
                    <h2 className="education-school">The University of Texas at San Antonio</h2>
                    <p className="education-degree">Bachelor's in Computer Science</p>
                    <p className="education-concentration">Concentration in: <br/>Cybersecurity<br/>Software development</p>
                    <p className="education-date">December 2020</p>
                </div>
                <h2 className="resume-label">Skills</h2>
                <h2 className="pro-languages"><TextLoop interval={1500} children={["Java", "C", "Python","React", "JavaScript", "SQL","Linux/Bash"]} /></h2>
                <br/>
              </div>
        </div>
    );
}

export default Resume;