import './Intro.css';
import HalfHalf from '../assets/halfHalf.png';


function Intro(){
return(
<div className="intro-container">
    <div className="left-pannel">
    
    </div>
    <div className="right-pannel">
    <h2 className="intro-title">Victor Plata</h2>
    <img className="half-half" src={HalfHalf}/>
    <p className="intro-text">
    Creative and hardworking programmer with a Bachelor's 
    in Computer Science interested in a Software Developer position. 
    Offering knowledge of various programming languages, problem-solving 
    and fast-learning skills.
    </p>
    </div>
</div>
);
}

export default Intro;