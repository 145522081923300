import './Projects.css';


function Projects(){
    return(
        <div className="projects-container">
            <p className="project-title">Projects</p>
            <div className="project-card-grid">
                <div className="project-card">
                    <div className="project-card-top">
                        <div className="red-circle"></div>
                        <div className="yellow-circle"></div>
                        <div className="green-circle"></div>
                    </div>
                    <div className="project-card-info">
                        <p className="command-prompt">{">"} Project.name</p>
                        <p className="command-response">={">"} Best Friend Finder</p>
                        <p className="command-prompt">{">"} Project.description</p>
                        <p className="command-response">
                        Best Friend Finder is a web application developed with HTML, 
                        CSS, and Javascript. It consists of two dictionaries. One has 
                        the possible owners as key and a list of their favorite dogs 
                        and vice-versa(both generated randomly every time the program runs). 
                        The algorithm will then find the best possible matches.
                        </p>
                        <div className="project-card-link-holder">
                        <a href='https://www.victorplata.com/stable/' target='_blank'><h2 className="project-card-link" >OPEN</h2></a>
                        <a href='https://github.com/vicplata/BestFriendFinder' target='_blank'><h2 className="project-card-link" >CODE</h2></a>
                        </div>
                    </div>
                </div>
                <div className="project-card">
                    <div className="project-card-top">
                        <div className="red-circle"></div>
                        <div className="yellow-circle"></div>
                        <div className="green-circle"></div>
                    </div>
                    <div className="project-card-info">
                        <p className="command-prompt">{">"} Project.name</p>
                        <p className="command-response">={">"} Sorting Algorithms Visualizer</p>
                        <p className="command-prompt">{">"} Project.description</p>
                        <p className="command-response">
                        The Algorithm Visualizer is a web app created using react. 
                        It shows with animations different sorting algorithms. 
                        The algorithms implemented are: Bubble Sort, Merge Sort, Quick Sort, 
                        and Select Sort. You can generate random arrays and manipulate 
                        the array size and animation speed.
                        </p>
                        <div className="project-card-link-holder">
                        <a href='https://www.victorplata.com/viewsort/' target='_blank'><h2 className="project-card-link" >OPEN</h2></a>
                        <a href='https://github.com/vicplata/View-sort' target='_blank'><h2 className="project-card-link" >CODE</h2></a>
                        </div>
                    </div>
                </div>
                <div className="project-card">
                    <div className="project-card-top">
                        <div className="red-circle"></div>
                        <div className="yellow-circle"></div>
                        <div className="green-circle"></div>
                    </div>
                    <div className="project-card-info">
                        <p className="command-prompt">{">"} Project.name</p>
                        <p className="command-response">={">"} Attack Replication</p>
                        <p className="command-prompt">{">"} Project.description</p>
                        <p className="command-response">
                        By analyzing IP packages the program identifies network attacks, 
                        and by modifying the IP packages the program automatically replicates 
                        the attack to other IP addresses. This package analyzer program also helps 
                        monitor the network and displays all packages in an easy-to-read format.
                        </p>
                        <div className="project-card-link-holder">
                        <a href='https://github.com/vicplata/Network-sniffer' target='_blank' style={{gridColumn:"1/3"}}><h2 className="project-card-link" >CODE</h2></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Projects;