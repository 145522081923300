import './App.css';
import Intro from './components/Intro';
import Face from './components/Face';
import Projects from './components/Projects';
import Resume from './components/Resume';

function App() {
  return (
    <div className="App">
    <Face></Face>
     <div id="section1"><Intro/></div>
     <div id="section2"><Projects/></div>
     <div id="section3"><Resume/></div>
   </div>
  );
}

export default App;
