import React from 'react';
import './Face.css';
import FaceLogo from '../assets/closeFace3.png';
import ClickMe from '../assets/clickMe.gif';
import FaceOpen from '../assets/openFace3.png';
import { HiOutlineOfficeBuilding, HiOutlineTerminal, HiHome, HiDocument, HiMail } from 'react-icons/hi';
import { GrGithub, GrLinkedinOption } from "react-icons/gr";
import { Link } from 'react-scroll';
import PDF from '../assets/Victor_Plata_Resume.pdf';

class Face extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            background: FaceLogo,
            isOpen: false,
            clicked: false
        };
    }

    faceAnimation(){
        if(this.state.isOpen){
            this.setState({
                background:FaceLogo,
                isOpen:false
            });
        }else{
            this.setState({
                background:FaceOpen,
                isOpen: true,
                clicked: true
            });
            /*
            setTimeout(() => {
                this.setState({
                    background:FaceOpen,
                    isOpen: true
                })
            }, 600)
            */
        }
       
        
    }

    render() {
        var bg=require('../assets/face.gif');
        return(
            <div className="menuHolder">
                
            <ul>
            <li>
                <Link activeClass="active" to="section1" spy={true} smooth={true} duration={1000}>
                    <HiHome className="icon" 
                    style={{visibility: this.state.isOpen ? 'visible': 'hidden'}}/>
                    <p className="link-text-home" style={{visibility: this.state.isOpen ? 'visible': 'hidden', marginTop:'-8px'}}>Home</p>
                </Link>
            </li>
            <li>
                <Link activeClass="active" to="section2" spy={true} smooth={true} duration={1000}>
                    <HiOutlineTerminal className="icon" 
                    style={{visibility: this.state.isOpen ? 'visible': 'hidden'}}/>
                    <p className="link-text-projects" style={{visibility: this.state.isOpen ? 'visible': 'hidden', marginTop:'-8px'}}>Projects</p>
                </Link>
            </li>
            <li>
                <Link activeClass="active" to="section3" spy={true} smooth={true} duration={1000}>
                    <HiOutlineOfficeBuilding className="icon" 
                    style={{visibility: this.state.isOpen ? 'visible': 'hidden'}}/>
                    <p className="link-text-experience" style={{visibility: this.state.isOpen ? 'visible': 'hidden', marginTop:'-8px'}}>Experience</p>
                </Link>
            </li>
            <li>
                <a href="mailto:vicoplatagon1@gmail.com">
                    <HiMail className="icon" 
                    style={{visibility: this.state.isOpen ? 'visible': 'hidden'}}/>
                    <p className="link-text-email" style={{visibility: this.state.isOpen ? 'visible': 'hidden', marginTop:'-8px'}}>Email</p>
                </a>
            </li>
            <li>
                <a href={PDF} target="_blank">
                    <HiDocument className="icon" 
                    style={{visibility: this.state.isOpen ? 'visible': 'hidden'}}/>
                    <p className="link-text-resume" style={{visibility: this.state.isOpen ? 'visible': 'hidden', marginTop:'-8px'}}>Resume</p>
                </a>
            </li>
            <li>
                <a href="https://github.com/vicplata" target="_blank">
                    <GrGithub className="icon" 
                    style={{visibility: this.state.isOpen ? 'visible': 'hidden'}}/>
                    <p className="link-text-resume" style={{visibility: this.state.isOpen ? 'visible': 'hidden', marginTop:'-8px'}}>GitHub</p>
                </a>
            </li>
            <li>
                <a href="https://linkedin.com/in/victor-plata-b98602aa" target="_blank">
                    <GrLinkedinOption className="icon" 
                    style={{visibility: this.state.isOpen ? 'visible': 'hidden'}}/>
                    <p className="link-text-resume" style={{visibility: this.state.isOpen ? 'visible': 'hidden', marginTop:'-8px'}}>LinkedIn</p>
                </a>
            </li>
            </ul>
            <div className="face"
            style={{backgroundImage: 'url(' +this.state.background + ')'}}
            onClick={() => this.faceAnimation()}
            >
            </div>

            <img className="click-me" src={ClickMe} style={{visibility: this.state.clicked ? 'hidden': 'visible'}}/>
             </div>
        );
    }
}

export default Face;